// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import styles from "~/tailwind.css";
import snow from "react-quill/dist/quill.snow.css";
export const links = () => [{
  rel: "stylesheet",
  href: styles
}, {
  rel: "stylesheet",
  href: snow
}];
import { Providers } from "./providers";
import { Toaster } from "react-hot-toast";
import { getFlashSession } from "./flash-session";
import { errorToast, successToast } from "./utils/toasters";
import { useEffect } from "react";
// import connectToDatabase from "./mongoose";

export default function App() {
  _s();
  const {
    flashSessionx
  } = useLoaderData();
  useEffect(() => {
    if (flashSessionx && flashSessionx.status === "error") {
      errorToast(flashSessionx.title, flashSessionx.message);
    }
    if (flashSessionx && flashSessionx.status === "success") {
      successToast(flashSessionx.title, flashSessionx.message);
    }
  }, [flashSessionx]);
  return <html lang='en'>
            <head>
                <meta charSet='utf-8' />
                <meta name='viewport' content='width=device-width, initial-scale=1' />

                <Meta />
                <Links />
            </head>
            <body>
                <Providers>
                    <Outlet />
                </Providers>
                <Toaster position='bottom-right' />
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
            </body>
        </html>;
}
_s(App, "eC2lIEpd/UDyDmRwOu3PNA7PexQ=", false, function () {
  return [useLoaderData];
});
_c = App;
export const loader = async ({
  request
}) => {
  const flashSession = await getFlashSession(request.headers.get("Cookie"));
  const alert = flashSession || {};
  return {
    flashSessionx: alert.data.__flash_alert__
  };
};
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;